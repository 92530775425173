var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"practice"},[_c('div',{staticClass:"practice-bg"},[_c('div',{staticClass:"practice-bg-model"},[_c('div',{},[_c('div',{staticClass:"practice-name"},[_vm._v(_vm._s(_vm.name))]),_c('div',{staticClass:"practice-sum"},[_vm._v("共"+_vm._s(_vm.total)+"题")])])])]),_c('div',{staticClass:"practice-content"},[_c('div',{staticClass:"practice-content-model"},[_c('div',{staticClass:"practice-content-model-menu"},[(_vm.trigger == '章节练习' ? true : false)?_c('div',{staticClass:"practice-content-model-menu-n",on:{"click":function($event){return _vm.toData('章节练习')}}},[_c('div',{class:_vm.trigger == '章节练习'
                ? 'practice-content-model-menu-n-name1'
                : 'practice-content-model-menu-n-name2'},[_vm._v(" 章节练习 ")]),_c('div',{class:_vm.trigger == '章节练习'
                ? 'practice-content-model-menu-n-line1'
                : 'practice-content-model-menu-n-line2'})]):_vm._e(),(_vm.trigger == '模拟试题' ? true : false)?_c('div',{staticClass:"practice-content-model-menu-n",on:{"click":function($event){return _vm.toData('模拟试题')}}},[_c('div',{class:_vm.trigger == '模拟试题'
                ? 'practice-content-model-menu-n-name1'
                : 'practice-content-model-menu-n-name2'},[_vm._v(" 模拟试题 ")]),_c('div',{class:_vm.trigger == '模拟试题'
                ? 'practice-content-model-menu-n-line1'
                : 'practice-content-model-menu-n-line2'})]):_vm._e(),(_vm.trigger == '历年真题' ? true : false)?_c('div',{staticClass:"practice-content-model-menu-n",on:{"click":function($event){return _vm.toData('历年真题')}}},[_c('div',{class:_vm.trigger == '历年真题'
                ? 'practice-content-model-menu-n-name1'
                : 'practice-content-model-menu-n-name2'},[_vm._v(" 历年真题 ")]),_c('div',{class:_vm.trigger == '历年真题'
                ? 'practice-content-model-menu-n-line1'
                : 'practice-content-model-menu-n-line2'})]):_vm._e()]),_c('div',{staticClass:"practice-content-content"},_vm._l((_vm.items.error_data),function(item,index){return _c('div',{key:index,staticClass:"practice-content-content-n"},[_c('div',{staticClass:"practice-content-content-n-left"},[_c('div',{staticClass:"practice-content-content-n-left-id"},[_vm._v(" "+_vm._s(_vm.repair(index + 1))+" ")]),_c('div',{staticClass:"practice-content-content-n-left-name"},[_vm._v(" "+_vm._s(item.ques_name)+" ")])]),_c('div',{staticClass:"practice-content-content-n-rigth"},[_c('div',{staticClass:"practice-content-content-n-rigth-1"},[(_vm.pageWidth <= 1024)?_c('div',{staticStyle:{"margin-right":"10px"}},[_vm._v("错题数")]):_vm._e(),_c('div',{staticClass:"practice-content-content-n-rigth-1-sum"},[_vm._v(" "+_vm._s(item.total)+" ")]),_c('div',{staticStyle:{"margin-left":"10px"}},[_vm._v("题")])]),_c('div',{staticClass:"see",on:{"click":function($event){return _vm.see(item.ques_id, item.ques_name)}}},[_vm._v(" 查看 ")])])])}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }