<template>
  <!--
    页面名称：我的题库-练习
    开发人：黄wj
  -->
  <div class="practice">
    <div class="practice-bg">
      <div class="practice-bg-model">
        <div class>
          <div class="practice-name">{{ name }}</div>
          <div class="practice-sum">共{{ total }}题</div>
        </div>
      </div>
    </div>
    <div class="practice-content">
      <div class="practice-content-model">
        <!-- 菜单 -->
        <div class="practice-content-model-menu">
          <div
            class="practice-content-model-menu-n"
            v-if="trigger == '章节练习' ? true : false"
            @click="toData('章节练习')"
          >
            <div
              :class="
                trigger == '章节练习'
                  ? 'practice-content-model-menu-n-name1'
                  : 'practice-content-model-menu-n-name2'
              "
            >
              章节练习
            </div>
            <div
              :class="
                trigger == '章节练习'
                  ? 'practice-content-model-menu-n-line1'
                  : 'practice-content-model-menu-n-line2'
              "
            ></div>
          </div>

          <div
            v-if="trigger == '模拟试题' ? true : false"
            class="practice-content-model-menu-n"
            @click="toData('模拟试题')"
          >
            <div
              :class="
                trigger == '模拟试题'
                  ? 'practice-content-model-menu-n-name1'
                  : 'practice-content-model-menu-n-name2'
              "
            >
              模拟试题
            </div>
            <div
              :class="
                trigger == '模拟试题'
                  ? 'practice-content-model-menu-n-line1'
                  : 'practice-content-model-menu-n-line2'
              "
            ></div>
          </div>

          <div
            v-if="trigger == '历年真题' ? true : false"
            class="practice-content-model-menu-n"
            @click="toData('历年真题')"
          >
            <div
              :class="
                trigger == '历年真题'
                  ? 'practice-content-model-menu-n-name1'
                  : 'practice-content-model-menu-n-name2'
              "
            >
              历年真题
            </div>
            <div
              :class="
                trigger == '历年真题'
                  ? 'practice-content-model-menu-n-line1'
                  : 'practice-content-model-menu-n-line2'
              "
            ></div>
          </div>
        </div>
        <!-- 内容 -->
        <div class="practice-content-content">
          <div
            class="practice-content-content-n"
            v-for="(item, index) in items.error_data"
            :key="index"
          >
            <div class="practice-content-content-n-left">
              <div class="practice-content-content-n-left-id">
                {{ repair(index + 1) }}
              </div>
              <div class="practice-content-content-n-left-name">
                {{ item.ques_name }}
              </div>
            </div>
            <div class="practice-content-content-n-rigth">
              <div class="practice-content-content-n-rigth-1">
                <div v-if="pageWidth <= 1024" style="margin-right: 10px;">错题数</div>
                <div class="practice-content-content-n-rigth-1-sum">
                  {{ item.total }}
                </div>
                <div style="margin-left: 10px;">题</div>
              </div>
              <div class="see" @click="see(item.ques_id, item.ques_name)">
                查看
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getResolution } from "../../../http/api/chapterOptions";
import {mapState} from "vuex";

export default {
  created() {
    //在data中定义的数据还没被绑定,所以不要在这里赋值
  },
  mounted() {
    //定义数据已经被绑定  可以在这里进行数据ajax绑定
    this.getData();
  },
  props: {
    //
  },

  data() {
    return {
      trigger: "章节练习",
      items: {},
      practiceList1: [], //章节练习
      practiceList2: [], //模拟试题
      practiceList3: [], //历年真题
      practiceList: [], //显示
      total: "",
      name: "",
      titleIndex: "0",
    };
  },
  computed: {
    ...mapState(['pageWidth']),
  },
  methods: {
    //加工
    repair(val) {
      var repair = "";
      if (val.toString().length <= 1) {
        repair = "0" + val;
      }
      return repair;
    },
    getData() {
      this.titleIndex = this.$router.history.current.query.titleIndex;
      if (this.titleIndex == "0") {
        this.trigger = "章节练习";
        // console.log("0");
      } else if (this.titleIndex == "2") {
        this.trigger = "模拟试题";
        // console.log("1");
      } else {
        this.trigger = "历年真题";
        // console.log("2");
      }
      var item = this.$router.history.current.query.items;
      this.items = JSON.parse(item);
      this.name = this.items.crs_name;
      this.total = this.items.total;
      // console.log(this.items);
    },
    see(ques_id, ques_name) {
      var items = this.$router.history.current.query.items;
      this.$router
        .push(
          `wrongAnalysis?ques_id=${ques_id}&ques_name=${ques_name}&name=${this.name}&items=${items}`
        )
        .then(() => {})
        .catch((err) => err);
    },

    toData(val) {
      this.trigger = val;
      if (val == "章节练习") {
        this.practiceList = this.practiceList1;
      } else if (val == "模拟试题") {
        this.practiceList = this.practiceList2;
      } else {
        this.practiceList = this.practiceList3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.practice-bg {
  width: 100%;
  height: 141px;
  background: url("../../../assets/img/exercise.png");
  background-size: cover;
}
.see {
  text-align: center;
  width: 84px;
  height: 32px;
  opacity: 1;
  line-height: 32px;
  background: #0080ff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: white;
}
.practice-bg-model {
  width: 67%;
  margin: auto;
  display: flex;
  padding-top: 5px;
  height: 155px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.practice-content-model {
  padding: 33px 0;
  width: 67%;
  margin: auto;
}
.practice-name {
  color: white;
  font-size: 18px;
}
.practice-content-content-n-left-id {
  font-size: 18px;
  color: #657489;
  font-weight: 600;
}
.practice-content-content-n-left-name {
  font-size: 16px;
  font-weight: 600;
  color: #00264d;
  margin-left: 19px;
}
.practice-sum {
  color: white;
  font-size: 14px;
  margin-top: 12px;
}
.practice-content-model-menu {
  display: flex;
}
.practice-content-model-menu-n {
  margin-right: 40px;
  cursor: pointer;
}
.practice-content-model-menu-n-name1 {
  font-size: 18px;
  padding-bottom: 12px;
  color: #00264d;
  font-weight: 600;
}
.practice-content-model-menu-n-name2 {
  font-size: 18px;
  color: #657489;
  padding-bottom: 12px;
}
.practice-content-model-menu-n-line1 {
  width: 100%;
  height: 2px;
  background: #0d80ff;

  transition: 0.5s;
}
.practice-content-model-menu-n-line2 {
  opacity: 0;
}
.practice-content-content {
  margin: 20px 0;
}
.practice-content-content-n {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  background: white;
  padding: 25px 33px;
  flex-wrap: wrap;
  margin: 20px 0;
  align-items: center;
}
.practice-content-content-n:hover {
  .practice-content-content-n-left-name {
    color: #2377e3;
  }
}
.practice-content-content-n-left {
  display: flex;
  line-height: 24px;
}
.practice-content-content-n-rigth {
  display: flex;
  justify-content: space-between;
}
.content-n-left-id {
  color: #657489;
  font-size: 18px;
  font-weight: 600;
}
.content-n-left-name {
  margin: 0 16px;
  color: #00264d;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.content-n-left-bg {
}
.content-n-left-bg1 {
  text-align: center;
  color: #ffffff;
  padding: 0 5px;
  border-radius: 4px;
  background: #0d80ff;
  font-size: 12px;
  line-height: 24px;
  cursor: pointer;
}
.content-n-left-bg2 {
  text-align: center;
  color: #ffffff;
  padding: 0 5px;
  border-radius: 4px;
  background: #fc6257;
  font-size: 12px;
  cursor: pointer;
  line-height: 24px;
}
.content-n-rigth-sum {
  display: flex;
  align-items: center;
}
.content-n-rigth-sum-1 {
  color: #657489;
  font-size: 14px;
}
.content-n-rigth-sum-2 {
  color: #2377e3;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}
.content-n-rigth-proportion {
  margin: 0 27px;
  display: flex;
  align-items: center;
  margin: 0 35px;
}
.practice-content-content-n-rigth-1-sum {
  color: #fc6257;
  font-size: 24px;
  font-weight: 500;
  margin-right: 5px;
}
.content-n-rigth-proportion-name {
  display: flex;
  align-items: center;
}
.content-n-rigth-proportion-name1 {
  color: #657489;
  font-size: 14px;
  margin-right: 8px;
}
.content-n-rigth-proportion-name2 {
  color: #fc6257;
  font-size: 11px;
  margin-left: 10px;
  font-weight: 600;
}
.content-n-rigth-proportion-proportion {
  width: 80px;
  height: 8px;
  border-radius: 5px;
  background: #eff0f5;
  overflow: hidden;
  margin-left: 10px;
}
.content-n-rigth-proportion-proportion-n {
  width: 80px;
  height: 8px;
  border-radius: 5px;
  background: #0080ff;
  position: relative;
  left: -100%;
  transition: 0.5s;
}
.content-n-rigth-proportion-analysis {
  padding: 6px 17px;
  color: #0080ff;
  border: 1px #0d80ff solid;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;
}
.content-n-rigth-proportion-redo {
  padding: 6px 28px;
  color: #ffffff;
  border-radius: 5px;
  text-align: center;
  margin-right: 10px;
  cursor: pointer;

  background: #0080ff;
}

.practice-content-content-n-rigth-1 {
  display: flex;
  align-items: center;
  margin-right: 40px;
  font-size: 16px;
}
/* 移动端 */
@media screen and (max-width: 1024px) {
  .practice-bg-model,
  .practice-content-model {
    padding: 0 20px;
    width: calc(100% - 40px);
  }
  .practice-content-model {
    padding: 20px 20px;
  }
  .practice-content-content-n{
    display: block;
    padding: 20px;
  }
  .practice-content-content-n-rigth{
    margin-top: 20px;
  }

}
</style>
